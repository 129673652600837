import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    MAX_LINE_LENGTH_CHAN_S,
    MAX_LINE_LENGTH_CHAN_S_RUN,
    MAX_LINE_LENGTH_CHAN_S_RUNPLUS,
    MAX_LINE_LENGTH_CHAN_S_ASYMMETRIC,
    MAX_LINE_LENGTH_CHAN_S_BOOST,
    MAX_LINE_LENGTH_FLOW_PCSAT,
    loadRulesets,
    renderDiffusorIcon,
    renderFormIcon,
    renderInstallationDetailText,
    renderInstallationIcon,
    renderInstallationText,
    renderDiffusorDetailText,
    renderDiffusorText,
    loadConfiguration,
    loadChannelSLightingSystem,
    renderRulesetImg,
    hideDiffusorsForSpecificForms,
    loadCombinations,
    renderPureliteSlimLightingIcon,
    renderPureliteSlimLightingText,
    renderPureliteSlimLightingDetailText,
    loadPureliteSlimLightingSystem,
    loadProfiles,
    loadEquipment,
    loadSystemComponents,
    loadSystemEquipment,
    loadFlowLightingSystem,
    loadPureliteLightingSystem,
    MAX_LINE_LENGTH_PURELITED_RUN,
    MAX_LINE_LENGTH_PURELITED_RUNPLUS,
    MAX_LINE_LENGTH_PURELITED_OPAL,
    MAX_LINE_LENGTH_PURELITE_OPAL,
    MAX_LINE_LENGTH_PURELITE_RUNPLUS,
    MAX_LINE_LENGTH_PURELITE_RUN,
} from '../services/service';
import {
    selectConfigurationDiffusor,
    selectForm,
    selectInstallation,
    selectRulesetID,
    setConfigurationDiffusor,
    setForm,
    setInstallation,
    setRulesetID,
    setConfigurationLineLengths,
    setConfigurationColor,
    setConfigurationProtection,
    setConfigurationPower,
    setConfigurationProductPriceEur,
    setConfigurationProductPriceChf,
    selectPureliteSlimLighting,
    setPureliteSlimLighting,
    setProjectName,
    setProjectAuthor,
    setConfigurationPendelLength,
    setFeedPointPosition,
    resetConfiguration,
    setEmergency,
    setAddWiring,
} from '../features/configuration/configurationSlice';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../components/Button';
import {
    selectDiffusors,
    selectMountingOptions,
    selectOpenConfiguratorPage2,
    selectShapes,
    setOpenConfiguratorPage2,
    setRuleset,
    setRulesetLightingSystemElements,
    setRulesetName,
    setUserSelectedForm,
    setUserLengths,
    setUserSelectedInstallation,
    setUserSelectedDiffusor,
    selectRulesetName,
    setProductCombinations,
    setUserSelectedColor,
    setUserSelectedPower,
    setUserSelectedProtection,
    setUserSelectedPureliteSlimLighting,
    setUserSelectedPendelLength,
    setProfiles,
    setEquipment,
    setSystemComponents,
    setSystemEquipment,
    setShowConfigLoadingModal,
    selectShowConfigLoadingModal,
    resetProductsSlice,
    setMaxLengthConfiguration,
    setUserSelectedEmergency,
    setUserWirings,
} from '../features/products/productsSlice';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingModal from '../components/LoadingModal';
import { FormattedMessage, useIntl } from 'react-intl';
import Translator, { imperativeTranslateMessage, translatedHint } from '../components/Translator';
import { DEBUG } from '../services/domainService';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

export default function Configurator() {
    const navigate = useNavigate();
    const intl = useIntl();
    const [searchParams, setSearchParams] = useSearchParams();
    const [rulesets, setRulesets] = useState(null);
    const dispatch = useDispatch();
    const currentRuleset = useSelector(selectRulesetID);
    const currentRulesetName = useSelector(selectRulesetName);
    const currentForm = useSelector(selectForm);
    const currentInstallation = useSelector(selectInstallation);
    const currentDiffusor = useSelector(selectConfigurationDiffusor);
    const availableShapes = useSelector(selectShapes);
    const mountingOptions = useSelector(selectMountingOptions);
    const diffusors = useSelector(selectDiffusors);
    const openConfiguratorPage2 = useSelector(selectOpenConfiguratorPage2);
    const lightingDirection = useSelector(selectPureliteSlimLighting);
    const showConfigLoadingModal = useSelector(selectShowConfigLoadingModal);

    const renderingExecuted = useRef(false);

    useEffect(() => {
        /**
         * Reset the whole configuration if you enter the start page --> prevent caching issue
         * Until a better way to securely handle the wrong states is found we use this solution.
         */
        dispatch(resetConfiguration());
        dispatch(resetProductsSlice());

        loadRulesets().then((result) => {
            setRulesets(result.data.data);
        });
        loadCombinations().then((result) => {
            dispatch(setProductCombinations(JSON.stringify(result.data)));
        });
        renderingExecuted.current = true;
    }, []);

    useEffect(() => {
        if (currentRuleset) {
            executeScrollPage1Button();
        }
    }, [currentRuleset]);

    useEffect(() => {
        if (currentForm) {
            if (currentRulesetName === 'Purelite Slim' || currentRulesetName === 'Purelite') {
                executeScrollPureliteSlimOptions();
            } else {
                executeScrollInstallation();

                if (currentDiffusor) {
                    //channel s can only have boost or asymmetric when form === I
                    if (currentForm !== 'I' && (currentDiffusor === 'boost' || currentDiffusor === 'asymmetric')) {
                        dispatch(setConfigurationDiffusor('run'));
                        dispatch(setUserSelectedDiffusor('run'));
                    }
                }
            }
        }
    }, [currentForm]);

    useEffect(() => {
        if (lightingDirection) {
            executeScrollInstallation();

            if (currentInstallation) {
                if (lightingDirection === 'raumstrahlend' && currentInstallation === 'recessed') {
                    dispatch(setInstallation('ceiling'));
                    dispatch(setUserSelectedInstallation('ceiling'));
                }
            }

            if (currentDiffusor) {
                if (lightingDirection === 'raumstrahlend') {
                    if (currentDiffusor === 'run') {
                        dispatch(setConfigurationDiffusor('run+'));
                        dispatch(setUserSelectedDiffusor('run+'));
                    }
                } else if (lightingDirection === 'direktstrahlend') {
                    dispatch(setConfigurationDiffusor('run'));
                    dispatch(setUserSelectedDiffusor('run'));
                }
            }
        }
    }, [lightingDirection]);

    useEffect(() => {
        if (currentInstallation) {
            executeScrollDiffusor();
        }
    }, [currentInstallation]);

    useEffect(() => {
        if (currentDiffusor) {
            executeScrollPage2Button();
        }
    }, [currentDiffusor]);

    useEffect(() => {
        if (openConfiguratorPage2) {
            executeScrollForm();
        }
    }, [openConfiguratorPage2]);

    /**
     * Wrapper function to load the configuration entered via querystring in the url on page load
     */
    useEffect(() => {
        const code = searchParams.get('code');
        if (code !== null) {
            loadCode(code);
        }
    }, []);

    const containerHeight = {
        height: 'calc(100vh - 84px)', //Header Height
    };

    const refPage1Button = useRef(null);
    const refForm = useRef(null);
    const refPureliteSlimOptions = useRef(null);
    const refInstallation = useRef(null);
    const refDiffusor = useRef(null);
    const refPage2Button = useRef(null);
    const executeScrollPage1Button = () => refPage1Button.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const executeScrollForm = () => refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const executeScrollPureliteSlimOptions = () => refPureliteSlimOptions.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const executeScrollInstallation = () => refInstallation.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const executeScrollDiffusor = () => refDiffusor.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const executeScrollPage2Button = () => refPage2Button.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    return (
        <div>
            {/* Page 1 */}
            <div className="popup hidden justify-center" id="popup">
                <div className="fixed form-popup justify-center top-1/3 w-96 h-56 z-20 bg-white text-black" id="customCode">
                    <form className="form-container" target="_blank">
                        <div className="flex justify-center">
                            <input
                                className="absolute code-input focus:outline-none w-80 border-b-2 border-black text-base placeholder:text-gray-500 pl-2 pb-2 top-16"
                                type="text"
                                placeholder={imperativeTranslateMessage(intl, 'configuration.code.placeholder')}
                                name="code"
                                required
                            />

                            <button
                                type="submit"
                                className="transition-all absolute w-80 h-12 bottom-11 bg-black text-white text-center text-xl hover:brightness-75"
                                onClick={(event) => {
                                    dispatch(setShowConfigLoadingModal(true));
                                    loadInputCode(event);
                                }}
                            >
                                <Translator id="configuration.code.button" />
                            </button>
                            <button type="button" className="absolute right-2.5 top-2.5 text-2xl" onClick={toggleCodeForm}>
                                <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {showConfigLoadingModal ? <LoadingModal action={'configLoad'} /> : null}
            <div className="popup hidden z-10 fixed inset-0 flex-wrap justify-center bg-black bg-opacity-50" id="popup-darken" onClick={toggleCodeForm} />

            <h1 className="text-h1 font-medium leading-[5.9375rem]">
                <Translator id="intro.header" />
            </h1>
            <h2 className="text-h2 leading-[4.4375rem]">
                <Translator id="intro.subheader" />
            </h2>

            <h3 className="text-h3 mt-36">
                <Translator id="intro.instructions.a" />
                <button className="transition-all duration-200 hover:brightness-75" onClick={toggleCodeForm}>
                    <u>
                        <Translator id="intro.instructions.b" />
                    </u>
                </button>
                <Translator id="intro.instructions.c" />
            </h3>

            {renderingExecuted ? (
                <div>
                    <div className="rulesets grid grid-cols-4 gap-8 mt-16">
                        {rulesets
                            ? rulesets.map((ruleset) => (
                                  <Button
                                      className={
                                          'hover:border-white group border-2 relative transition ease-in-out ' +
                                          (currentRuleset === ruleset.id ? 'border-white' : 'border-disabled-border-grey')
                                      }
                                      key={ruleset.id}
                                      onClick={() => {
                                          dispatch(setRulesetID(ruleset.id));
                                          dispatch(setRuleset(ruleset.rules));
                                          dispatch(setRulesetName(ruleset.name));
                                          dispatch(setForm(null));
                                          dispatch(setInstallation(null));
                                          dispatch(setConfigurationDiffusor(null));
                                          dispatch(setUserSelectedInstallation(null));
                                          dispatch(setUserSelectedForm(null));
                                          dispatch(setUserSelectedDiffusor(null));
                                          dispatch(setUserSelectedPureliteSlimLighting(null));
                                          dispatch(setPureliteSlimLighting(null));
                                          if (ruleset.name === 'Purelite Slim' || ruleset.name === 'Purelite' || ruleset.name === 'Flow') {
                                              dispatch(setForm('I'));
                                              dispatch(setUserSelectedForm('I'));
                                          }
                                      }}
                                  >
                                      {currentRuleset === ruleset.id ? (
                                          <img className="object-contain" src={renderRulesetImg(ruleset.name, true)} alt="" />
                                      ) : null}
                                      {currentRuleset !== ruleset.id ? (
                                          <img
                                              className="object-contain transition-all duration-300 group-hover:brightness-150"
                                              src={renderRulesetImg(ruleset.name, false)}
                                              alt=""
                                          />
                                      ) : null}
                                      <div className="absolute left-6 bottom-2 text-left">
                                          <h4 className={'text-30 font-medium leading-10 ' + (currentRuleset === ruleset.id ? '' : 'text-disabled-grey')}>
                                              {ruleset.name}
                                              <Translator id="lights.header.family" />
                                          </h4>
                                          {currentRuleset === ruleset.id ? (
                                              <p className="text-20">
                                                  <Translator id="channel.subheader" />
                                              </p>
                                          ) : null}
                                      </div>
                                  </Button>
                              ))
                            : null}
                    </div>

                    <div className="text-center" ref={refPage1Button}>
                        <Button
                            className={
                                'transition-all duration-200 mt-16 text-xl border-2 px-6 py-2 ' +
                                (currentRuleset ? 'border-white hover:brightness-75' : 'border-disabled-border-grey text-disabled-grey')
                            }
                            disabled={currentRuleset ? '' : true}
                            onClick={() => {
                                //setting default values of color, power & protection
                                dispatch(setUserSelectedColor({ kelvin: 4000, name: 'Neutralweiss' }));
                                dispatch(setConfigurationColor({ kelvin: 4000, name: 'Neutralweiss' }));
                                dispatch(setUserSelectedPower({ name: 'HPE', lumen: null, watt: null }));
                                dispatch(setConfigurationPower({ name: 'HPE', lumen: null, watt: null }));
                                dispatch(setUserSelectedProtection('IP20'));
                                dispatch(setConfigurationProtection('IP20'));
                                dispatch(setOpenConfiguratorPage2(true));
                            }}
                        >
                            <Translator id="continue.basic.selections" />
                        </Button>
                        {DEBUG ? (
                            <Button
                                className={
                                    'transition-all duration-200 mt-16 text-xl border-2 ml-4 px-6 py-2 ' +
                                    (currentRuleset ? 'border-white hover:brightness-75' : 'border-disabled-border-grey text-disabled-grey')
                                }
                                onClick={() => {
                                    navigate('/test');
                                }}
                            >
                                TEST
                            </Button>
                        ) : null}
                    </div>

                    {/* Page 2 */}

                    <div
                        className={
                            'fixed top-full left-0 right-0 transition-top duration-300 bg-black overflow-y-scroll ' + (openConfiguratorPage2 ? 'top-21' : '')
                        }
                        style={containerHeight}
                    >
                        <div className="mx-auto max-w-screen-xl px-4 py-8">
                            {currentRuleset ? (
                                <div className="forms mt-8" ref={refForm}>
                                    <h2 className="text-36 mb-4">
                                        <Translator id="intro.form.title" />
                                    </h2>

                                    <div className="grid grid-cols-3 gap-5">
                                        {availableShapes
                                            ? availableShapes.map((shape) => (
                                                  <Button
                                                      className={
                                                          'hover:border-white border-2 bg-black text-left text-white transition ease-in-out ' +
                                                          (currentForm === shape ? 'bg-white text-black' : 'border-disabled-border-grey')
                                                      }
                                                      key={shape}
                                                      onClick={() => {
                                                          dispatch(setForm(shape));
                                                          dispatch(setUserSelectedForm(shape));
                                                      }}
                                                  >
                                                      <span className="flex flex-wrap">
                                                          <span
                                                              className={
                                                                  'w-24 transition ease-in-out ' +
                                                                  (currentForm === shape ? 'bg-white' : 'bg-disabled-property-grey')
                                                              }
                                                          >
                                                              <img width={100} height={100} src={renderFormIcon(shape, currentForm === shape)} alt="" />
                                                          </span>
                                                          <span className="flex flex-wrap content-center px-6 flex-1 text-lg">
                                                              <Translator id={'intro.form.suffix'} values={{ status: translatedHint, form: shape }} />
                                                          </span>
                                                      </span>
                                                  </Button>
                                              ))
                                            : null}
                                    </div>
                                </div>
                            ) : null}

                            {currentForm && (currentRulesetName === 'Purelite Slim' || currentRulesetName === 'Purelite') ? (
                                <>
                                    <div className="forms mt-32" ref={refPureliteSlimOptions}>
                                        <h2 className="text-36 mb-4">
                                            <Translator id="light.direction.type" />
                                        </h2>

                                        <div className="grid grid-cols-3 gap-5">
                                            <Button
                                                className={
                                                    'hover:border-white border-2 bg-black text-left text-white transition ease-in-out ' +
                                                    (lightingDirection === 'direktstrahlend' ? 'bg-white text-black' : 'border-disabled-border-grey')
                                                }
                                                onClick={() => {
                                                    dispatch(setPureliteSlimLighting('direktstrahlend'));
                                                    dispatch(setUserSelectedPureliteSlimLighting('direktstrahlend'));
                                                }}
                                            >
                                                <span className="flex flex-wrap">
                                                    <span
                                                        className={
                                                            'w-24 transition ease-in-out ' +
                                                            (lightingDirection === 'direktstrahlend' ? 'bg-white' : 'bg-disabled-property-grey')
                                                        }
                                                    >
                                                        <img
                                                            className="w-[100px] h-[100px]"
                                                            width={100}
                                                            height={100}
                                                            src={renderPureliteSlimLightingIcon('direktstrahlend')}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="flex flex-wrap content-center justify-start px-6 flex-1">
                                                        <div>
                                                            <span className="text-lg block">
                                                                <Translator id={renderPureliteSlimLightingText('direktstrahlend')} />
                                                            </span>
                                                            <span className="text-14">
                                                                <Translator id={renderPureliteSlimLightingDetailText('direktstrahlend')} />
                                                            </span>
                                                        </div>
                                                    </span>
                                                </span>
                                            </Button>
                                            <Button
                                                className={
                                                    'hover:border-white border-2 bg-black text-left text-white transition ease-in-out ' +
                                                    (lightingDirection === 'raumstrahlend' ? 'bg-white text-black' : 'border-disabled-border-grey')
                                                }
                                                onClick={() => {
                                                    dispatch(setPureliteSlimLighting('raumstrahlend'));
                                                    dispatch(setUserSelectedPureliteSlimLighting('raumstrahlend'));
                                                }}
                                            >
                                                <span className="flex flex-wrap">
                                                    <span
                                                        className={
                                                            'w-24 transition ease-in-out ' +
                                                            (lightingDirection === 'raumstrahlend' ? 'bg-white' : 'bg-disabled-property-grey')
                                                        }
                                                    >
                                                        <img
                                                            className="w-[100px] h-[100px]"
                                                            width={100}
                                                            height={100}
                                                            src={renderPureliteSlimLightingIcon('raumstrahlend')}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <span className="flex flex-wrap content-center justify-start px-6 flex-1">
                                                        <div>
                                                            <span className="text-lg block">
                                                                <Translator id={renderPureliteSlimLightingText('raumstrahlend')} />
                                                            </span>
                                                            <span className="text-14">
                                                                <Translator id={renderPureliteSlimLightingDetailText('raumstrahlend')} />
                                                            </span>
                                                        </div>
                                                    </span>
                                                </span>
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            ) : null}

                            {currentForm &&
                            (((currentRulesetName === 'Purelite Slim' || currentRulesetName === 'Purelite') && lightingDirection) ||
                                currentRulesetName === 'Channel S' ||
                                currentRulesetName === 'Flow') ? (
                                <div className="forms mt-32" ref={refInstallation}>
                                    <h2 className="text-36 mb-4">
                                        <Translator id="assembly.type" />
                                    </h2>

                                    <div className="grid grid-cols-3 gap-5">
                                        {mountingOptions
                                            ? mountingOptions.map((mount) => (
                                                  <Button
                                                      className={
                                                          'hover:border-white border-2 bg-black text-left text-white transition ease-in-out' +
                                                          (currentInstallation === mount ? ' bg-white text-black' : ' border-disabled-border-grey') +
                                                          (mount === 'recessed' &&
                                                          currentRulesetName === 'Purelite Slim' &&
                                                          lightingDirection === 'raumstrahlend'
                                                              ? ' opacity-40 cursor-not-allowed hover:border-transparent'
                                                              : '')
                                                      }
                                                      key={mount}
                                                      onClick={() => {
                                                          if (
                                                              !(
                                                                  mount === 'recessed' &&
                                                                  currentRulesetName === 'Purelite Slim' &&
                                                                  lightingDirection === 'raumstrahlend'
                                                              )
                                                          ) {
                                                              dispatch(setInstallation(mount));
                                                              dispatch(setUserSelectedInstallation(mount));
                                                          }
                                                      }}
                                                  >
                                                      <span className="flex flex-wrap">
                                                          <span
                                                              className={
                                                                  'w-24 transition ease-in-out ' +
                                                                  (currentInstallation === mount ? 'bg-white' : 'bg-disabled-property-grey')
                                                              }
                                                          >
                                                              <img
                                                                  width={100}
                                                                  height={100}
                                                                  src={renderInstallationIcon(mount, currentInstallation === mount)}
                                                                  alt=""
                                                              />
                                                          </span>
                                                          <span className="flex flex-wrap content-center justify-start px-6 flex-1">
                                                              <div>
                                                                  <span className="text-lg block">
                                                                      <Translator id={renderInstallationText(mount)} />
                                                                  </span>
                                                                  <span className="text-14">
                                                                      <Translator id={renderInstallationDetailText(mount)} />
                                                                  </span>
                                                              </div>
                                                          </span>
                                                      </span>
                                                  </Button>
                                              ))
                                            : null}
                                    </div>
                                </div>
                            ) : null}

                            {currentInstallation ? (
                                <div className="forms mt-32" ref={refDiffusor}>
                                    <h2 className="text-36 mb-4">
                                        <Translator id="design.type" />
                                    </h2>

                                    <div className="flex flex-wrap items-start">
                                        {diffusors
                                            ? Object.entries(diffusors).map(([key, value]) => {
                                                  return (
                                                      <Button
                                                          className={
                                                              'hover:border-white w-56 mr-8 mb-8 last:mr-0 bg-black text-left text-white transition ease-in-out border-2 flex flex-wrap content-start ' +
                                                              (currentDiffusor === key ? 'bg-white text-black border-white' : 'border-transparent') +
                                                              hideDiffusorsForSpecificForms(currentRulesetName, currentForm, key, lightingDirection).css
                                                          }
                                                          key={'diffusor-' + key}
                                                          onClick={() => {
                                                              if (
                                                                  hideDiffusorsForSpecificForms(currentRulesetName, currentForm, key, lightingDirection)
                                                                      .isEnabled
                                                              ) {
                                                                  dispatch(setConfigurationDiffusor(key));
                                                                  dispatch(setUserSelectedDiffusor(key));

                                                                  setMaxLength(key, currentRuleset, lightingDirection);
                                                              }
                                                          }}
                                                      >
                                                          <div className="transition ease-in-out mb-2.5">
                                                              <img
                                                                  width={220}
                                                                  height={220}
                                                                  src={renderDiffusorIcon(key, currentRulesetName, lightingDirection)}
                                                                  alt=""
                                                              />
                                                          </div>
                                                          <div className="flex flex-wrap w-full content-center justify-start px-2.5 flex-1">
                                                              <div>
                                                                  <span className="text-lg block">
                                                                      <Translator id={renderDiffusorText(key, currentRulesetName)} />
                                                                  </span>
                                                                  <span className="text-14">
                                                                      <Translator id={renderDiffusorDetailText(key, currentRulesetName)} />
                                                                  </span>
                                                              </div>
                                                          </div>
                                                      </Button>
                                                  );
                                              })
                                            : null}
                                    </div>
                                </div>
                            ) : null}
                            {currentDiffusor ? (
                                <div className="text-center" ref={refPage2Button}>
                                    <Link to="/inputscreen">
                                        <Button
                                            onClick={() => {
                                                if (currentRulesetName === 'Flow') {
                                                    if (currentInstallation === 'pendulum') {
                                                        dispatch(setConfigurationProtection('IP40'));
                                                        dispatch(setUserSelectedProtection('IP40'));
                                                    } else {
                                                        dispatch(setConfigurationProtection('IP44'));
                                                        dispatch(setUserSelectedProtection('IP44'));
                                                    }
                                                }
                                            }}
                                            className="transition-all duration-200 hover:brightness-75 mt-16 text-xl border-2 px-6 py-2 border-white"
                                        >
                                            <Translator id={'start.calculation.button'} />
                                        </Button>
                                    </Link>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );

    /**
     * Set all the store values with the requested configuration values.
     *
     * @param {*} fetchedStore
     * @return {*}
     */
    function updateStoreElements(fetchedStore) {
        return new Promise((resolve, reject) => {
            // Set ruleset to all fetched rulesets
            rulesets.forEach((ruleset) => {
                if (ruleset.id === fetchedStore.ruleset_id) {
                    dispatch(setRulesetID(ruleset.id));
                    dispatch(setRuleset(ruleset.rules));
                    dispatch(setRulesetName(ruleset.name));

                    if (ruleset.name === 'Channel S') {
                        loadChannelSLightingSystem({ id: ruleset.id }).then((result) => {
                            dispatch(setRulesetLightingSystemElements(result.data));
                        });
                    } else if (ruleset.name === 'Purelite Slim') {
                        loadPureliteSlimLightingSystem({ id: ruleset.id }).then((result) => {
                            dispatch(setRulesetLightingSystemElements(result.data));
                        });
                    } else if (ruleset.name === 'Flow') {
                        loadFlowLightingSystem({ id: ruleset.id }).then((result) => {
                            dispatch(setRulesetLightingSystemElements(result.data));
                        });
                    } else if (ruleset.name === 'Purelite') {
                        loadPureliteLightingSystem({ id: ruleset.id }).then((result) => {
                            dispatch(setRulesetLightingSystemElements(result.data));
                        });
                    }

                    loadProfiles({ id: ruleset.id }).then((result) => {
                        dispatch(setProfiles(JSON.stringify(result.data)));
                    });

                    loadEquipment({ id: ruleset.id }).then((result) => {
                        dispatch(setEquipment(JSON.stringify(result.data)));
                    });

                    loadSystemComponents({ id: ruleset.id }).then((result) => {
                        dispatch(setSystemComponents(JSON.stringify(result.data)));
                    });

                    loadSystemEquipment({ id: ruleset.id }).then((result) => {
                        dispatch(setSystemEquipment(JSON.stringify(result.data)));
                    });
                }
            });

            setTimeout(function () {
                //ConfigurationSlice
                dispatch(setForm(fetchedStore.form));
                dispatch(setInstallation(fetchedStore.installation));
                dispatch(setConfigurationLineLengths(fetchedStore.configurationLineLengths));
                dispatch(setConfigurationColor(fetchedStore.color));
                dispatch(setConfigurationProtection(fetchedStore.protection));
                dispatch(setConfigurationDiffusor(fetchedStore.diffusor));
                dispatch(setConfigurationPower(fetchedStore.power));
                dispatch(setConfigurationProductPriceEur(fetchedStore.configurationProductPriceEur));
                dispatch(setConfigurationProductPriceChf(fetchedStore.configurationProductPriceChf));
                dispatch(setProjectName(fetchedStore.projectName));
                dispatch(setProjectAuthor(fetchedStore.projectAuthor));
                dispatch(setPureliteSlimLighting(fetchedStore.pureliteSlimLighting));
                dispatch(setConfigurationPendelLength(fetchedStore.configurationPendelLength));
                dispatch(setFeedPointPosition(fetchedStore.feedPointPosition));
                dispatch(setEmergency(fetchedStore.useEmergency));
                dispatch(setAddWiring(fetchedStore.addWiring));

                //ProductSlice:
                dispatch(setUserSelectedForm(fetchedStore.form));
                dispatch(setUserSelectedInstallation(fetchedStore.installation));
                dispatch(setUserSelectedDiffusor(fetchedStore.diffusor));
                dispatch(setUserSelectedColor(fetchedStore.color));
                dispatch(setUserSelectedPower(fetchedStore.power));
                dispatch(setUserSelectedProtection(fetchedStore.protection));
                dispatch(setUserSelectedPendelLength(fetchedStore.configurationPendelLength));
                dispatch(setUserSelectedPureliteSlimLighting(fetchedStore.pureliteSlimLighting));
                dispatch(setUserSelectedEmergency(fetchedStore.useEmergency));
                dispatch(setUserWirings(fetchedStore.addWiring));
                dispatch(setUserLengths(fetchedStore.configurationLineLengths));
                setMaxLength(fetchedStore.diffusor, fetchedStore.ruleset, fetchedStore.pureliteSlimLighting);
            }, 2000);

            setTimeout(function () {
                resolve('Wait a second to ensure dispatches fired before anything else is done.');
            }, 1000);
        });
    }

    /**
     * Toggle the input form of the configuration code.
     *
     */
    function toggleCodeForm(e) {
        // eslint-disable-next-line eqeqeq
        if (e.currentTarget.className == 'customCode') {
            return false;
        }

        if (document.getElementById('popup').classList.contains('hidden')) {
            for (let element of document.getElementsByClassName('popup')) {
                element.classList.add('flex');
                element.classList.remove('hidden');
            }
        } else {
            for (let element of document.getElementsByClassName('popup')) {
                element.classList.add('hidden');
                element.classList.remove('flex');
            }
        }
    }

    /**
     * Wrapper function to load the configuration of the code entered into the modal.
     * @param {*} event
     */
    function loadInputCode(event) {
        event.preventDefault();
        Array.from(document.getElementsByClassName('form-container')).forEach((/** @type {HTMLFormElement} */ element) => {
            if (element.reportValidity()) {
                const inputs = event.nativeEvent.target.parentElement.querySelectorAll('.code-input');
                for (let i = 0; i < inputs.length; i++) {
                    loadCode(inputs.item(i).value);
                }
            }
        });
    }

    /**
     * Start the loading process of a configuration belonging to a code
     * @param {string} code
     */
    function loadCode(code) {
        loadConfiguration(code)
            .then((response) => {
                let stateUpdate = JSON.parse(response.data['state']);

                updateStoreElements(stateUpdate).then((response) => {
                    dispatch(setShowConfigLoadingModal(false));
                    navigate('/inputscreen', { state: { wasLoaded: true } });
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /**
     * Sets the available max length of a line for this type of configuration
     * @param {*} diffusor
     * @param {*} rulesetId
     */
    function setMaxLength(diffusor, rulesetId, lightingDirection = null) {
        if (rulesetId === 4) {
            if (lightingDirection === 'direktstrahlend') {
                switch (diffusor.toLowerCase()) {
                    case 'run':
                        dispatch(setMaxLengthConfiguration(MAX_LINE_LENGTH_PURELITED_RUN));
                        break;
                    case 'run+':
                        dispatch(setMaxLengthConfiguration(MAX_LINE_LENGTH_PURELITED_RUNPLUS));
                        break;
                    case 'opal':
                        dispatch(setMaxLengthConfiguration(MAX_LINE_LENGTH_PURELITED_OPAL));
                        break;
                    default:
                        break;
                }
            } else if (lightingDirection === 'raumstrahlend') {
                switch (diffusor.toLowerCase()) {
                    case 'run':
                        dispatch(setMaxLengthConfiguration(MAX_LINE_LENGTH_PURELITE_RUN));
                        break;
                    case 'run+':
                        dispatch(setMaxLengthConfiguration(MAX_LINE_LENGTH_PURELITE_RUNPLUS));
                        break;
                    case 'opal':
                        dispatch(setMaxLengthConfiguration(MAX_LINE_LENGTH_PURELITE_OPAL));
                        break;
                    default:
                        break;
                }
            }
        } else {
            switch (diffusor.toLowerCase()) {
                case 'run':
                    dispatch(setMaxLengthConfiguration(MAX_LINE_LENGTH_CHAN_S_RUN));
                    break;
                case 'run+':
                    dispatch(setMaxLengthConfiguration(MAX_LINE_LENGTH_CHAN_S_RUNPLUS));
                    break;
                case 'boost':
                    dispatch(setMaxLengthConfiguration(MAX_LINE_LENGTH_CHAN_S_BOOST));
                    break;
                case 'asymmetric':
                    dispatch(setMaxLengthConfiguration(MAX_LINE_LENGTH_CHAN_S_ASYMMETRIC));
                    break;
                case 'opal':
                    dispatch(setMaxLengthConfiguration(MAX_LINE_LENGTH_CHAN_S));
                    break;
                case 'pcsat':
                    dispatch(setMaxLengthConfiguration(MAX_LINE_LENGTH_FLOW_PCSAT));
                    break;
                default:
                    break;
            }
        }
    }
}
