import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCountryCode,
    selectRulesetID,
    setRulesetID,
    setForm,
    setConfigurationColor,
    setConfigurationPower,
    setConfigurationProtection,
    setPureliteSlimLighting,
    setInstallation,
    setConfigurationDiffusor,
    setConfigurationLineLengths,
    selectConfigurationID,
    selectConfigurationPower,
    selectConfigurationColor,
} from '../features/configuration/configurationSlice';
import {
    createDialuxDownload,
    createReluxDownload,
    loadChannelSLightingSystem,
    loadCombinations,
    loadEquipment,
    loadFlowLightingSystem,
    loadProfiles,
    loadPureliteLightingSystem,
    loadPureliteSlimLightingSystem,
    loadSingleRuleset,
    loadSystemComponents,
    loadSystemEquipment,
    SWISSCODE,
} from '../services/service';
import {
    setProductCombinations,
    setRuleset,
    setRulesetName,
    setUserSelectedForm,
    setUserSelectedDiffusor,
    setUserSelectedPureliteSlimLighting,
    setUserSelectedColor,
    setUserSelectedPower,
    setUserSelectedProtection,
    setUserSelectedInstallation,
    setMaxLengthConfiguration,
    setLinesCount,
    setUserLengths,
    setUserSelectedPendelLength,
    setRulesetLightingSystemElements,
    setProfiles,
    setEquipment,
    setSystemComponents,
    setSystemEquipment,
    selectRulesetName,
    selectIsCHF,
    selectUserProducts,
    selectUserDiffusors,
    selectProductCombinations,
    selectUserSelectedInstallation,
    selectUserSelectedForm,
    selectPowers,
} from '../features/products/productsSlice';
import { useBackListener } from '../services/useBackListener';
import ArticleList from '../components/ArticleList';
import Button from '../components/Button';

export function generateRandomUUID() {
    // // Maximum length of the string & possible characters the string can contain
    const characters = 'abcdef0123456789';
    const maxLength = 16;

    let length = Math.floor(Math.random() * maxLength) + 1;
    length = 16;

    let randomString = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters[randomIndex];
    }

    return randomString;
}

function DialuxButton() {
    const [selectedDialuxExport, setSelectedDialuxExport] = useState(false);
    const [dialuxDownloadFinished, setDialuxDownloadFinished] = useState(true);
    const [selectedReluxExport, setSelectedReluxExport] = useState(false);
    const [reluxDownloadFinished, setReluxDownloadFinished] = useState(true);
    const userProducts = useSelector(selectUserProducts);
    const currentDiffusor = useSelector(selectUserDiffusors);
    const combinationProducts = useSelector(selectProductCombinations);
    const currentInstallation = useSelector(selectUserSelectedInstallation);
    const rulesetName = useSelector(selectRulesetName);
    const currentColor = useSelector(selectConfigurationColor);
    const currentPower = useSelector(selectConfigurationPower);
    const currentForm = useSelector(selectUserSelectedForm);
    const configurationID = useSelector(selectConfigurationID) ?? generateRandomUUID();
    useEffect(() => {
        if (selectedDialuxExport) {
            setDialuxDownloadFinished(false);
            createDialuxDownload(
                userProducts,
                currentDiffusor,
                combinationProducts,
                currentInstallation,
                rulesetName,
                currentColor,
                currentPower,
                currentForm,
                configurationID
            )
                .then((r) => {
                    setDialuxDownloadFinished(true);
                    setSelectedDialuxExport(false);
                })
                .catch(() => {
                    setSelectedDialuxExport(false);
                });
        } else if (selectedReluxExport) {
            setReluxDownloadFinished(false);
            createReluxDownload(userProducts, currentDiffusor, combinationProducts, configurationID, 'project_name', currentPower, currentForm)
                .then((r) => {
                    setReluxDownloadFinished(true);
                    setSelectedReluxExport(false);
                })
                .catch(() => {
                    setSelectedReluxExport(false);
                });
        }
    }, [selectedDialuxExport, selectedReluxExport]);
    const powers = useSelector(selectPowers);
    return (
        <div className="grid grid-cols-2 gap-4 my-4 justify-self-center ">
            <Button
                className="border px-2 max-w-sm"
                onClick={() => {
                    setSelectedDialuxExport(true);
                }}
            >
                Create Dialux
            </Button>
            <Button
                className="border px-2 max-w-sm"
                onClick={() => {
                    setSelectedReluxExport(true);
                }}
            >
                Create Relux
            </Button>
        </div>
    );
}

export default function Test() {
    const dispatch = useDispatch();
    const [length, setLength] = useState('20000');
    const [usedButton, setUsedButton] = useState(null);
    const [lastCalculation, setLastCalculation] = useState('');
    const currentRulesetID = useSelector(selectRulesetID);
    const [productType, setProductType] = useState(4);
    const [productTypeName, setProductTypeName] = useState('');
    const rulesetName = useSelector(selectRulesetName);

    useEffect(() => {
        loadSingleRuleset({ id: productType }).then((result) => {
            console.log('It is: ' + productType);
            dispatch(setRulesetID(productType));
            dispatch(setRuleset(result.data.rules));
            dispatch(setRulesetName(result.data.name));
            loadCombinations().then((result) => {
                dispatch(setProductCombinations(JSON.stringify(result.data)));
            });
            let ruleset = result.data;
            dispatch(setRulesetID(ruleset.id));
            dispatch(setRuleset(ruleset.rules));
            dispatch(setRulesetName(ruleset.name));

            if (ruleset.name === 'Channel S') {
                loadChannelSLightingSystem({ id: ruleset.id }).then((result) => {
                    dispatch(setRulesetLightingSystemElements(result.data));
                });
            } else if (ruleset.name === 'Purelite Slim') {
                loadPureliteSlimLightingSystem({ id: ruleset.id }).then((result) => {
                    dispatch(setRulesetLightingSystemElements(result.data));
                });
            } else if (ruleset.name === 'Flow') {
                loadFlowLightingSystem({ id: ruleset.id }).then((result) => {
                    dispatch(setRulesetLightingSystemElements(result.data));
                });
            } else if (ruleset.name === 'Purelite') {
                loadPureliteLightingSystem({ id: ruleset.id }).then((result) => {
                    dispatch(setRulesetLightingSystemElements(result.data));
                });
            }

            loadProfiles({ id: ruleset.id }).then((result) => {
                dispatch(setProfiles(JSON.stringify(result.data)));
            });

            loadEquipment({ id: ruleset.id }).then((result) => {
                dispatch(setEquipment(JSON.stringify(result.data)));
            });

            loadSystemComponents({ id: ruleset.id }).then((result) => {
                dispatch(setSystemComponents(JSON.stringify(result.data)));
            });

            loadSystemEquipment({ id: ruleset.id }).then((result) => {
                dispatch(setSystemEquipment(JSON.stringify(result.data)));
            });
        });
    }, [productType]);

    //Listener for Browser Back Function
    useBackListener();

    // Country specific formatting
    const countryCode = useSelector(selectCountryCode);
    let isLanguageCH = useSelector(selectIsCHF);
    let thousandSeparator = isLanguageCH ? "'" : '.';
    let decimalSeparator = isLanguageCH ? ',' : ',';

    function setValues(lineLength, power, color, lightDirection, mounting, form, diffusor) {
        dispatch(setForm(form));
        dispatch(setInstallation(mounting));
        dispatch(setConfigurationDiffusor(diffusor));
        dispatch(setUserSelectedInstallation(mounting));
        dispatch(setUserSelectedForm(form));
        dispatch(setUserSelectedDiffusor(diffusor));
        dispatch(setUserSelectedPureliteSlimLighting(lightDirection));

        // dispatch(setCountryCode());
        // dispatch(setRulesetID());
        // dispatch(setRuleset());
        // dispatch(setRulesetName());
        dispatch(setInstallation(mounting));
        dispatch(setConfigurationDiffusor(diffusor));
        dispatch(setUserSelectedInstallation(mounting));
        dispatch(setUserSelectedForm(form));
        dispatch(setUserSelectedDiffusor(diffusor));
        dispatch(setUserSelectedPureliteSlimLighting(lightDirection));
        dispatch(setPureliteSlimLighting(lightDirection));
        dispatch(setUserSelectedColor(color));
        dispatch(setConfigurationColor(color));
        dispatch(setUserSelectedPower(power));
        dispatch(setConfigurationPower(power));
        dispatch(setUserSelectedProtection('IP20'));
        dispatch(setConfigurationProtection('IP20'));
        dispatch(setMaxLengthConfiguration(44000));
        dispatch(setUserSelectedPendelLength(1500));

        let lines = {
            line1: lineLength,
            line2: null,
            line3: null,
            line4: null,
        };
        dispatch(setLinesCount(1));
        dispatch(setUserLengths(lines));
        dispatch(setConfigurationLineLengths(lines));
    }

    return (
        <>
            <div className="flex justify-center">
                <div className="flex items-center mr-12">
                    <p className="p-2">Length:</p>
                    <select
                        defaultValue={productType}
                        className="bg-black"
                        onChange={(element) => {
                            setProductType(parseInt(element.currentTarget.value));
                        }}
                    >
                        {/* <option value="1">Channel S</option>
                        <option value="2">Purelite Slim</option> */}
                        <option value="3">Flow</option>
                        <option value="4">Purelite</option>
                    </select>
                </div>
                <div className="flex items-center mr-12">
                    <p className="p-2">Length:</p>
                    <select
                        defaultValue={length}
                        className="bg-black"
                        onChange={(element) => {
                            setLength(element.currentTarget.value);
                        }}
                    >
                        <option value="2000">2000</option>
                        <option value="5000">5000</option>
                        <option value="7000">7000</option>
                        <option value="10000">10000</option>
                        <option value="12000">12000</option>
                        <option value="17000">17000</option>
                        <option value="20000">20000</option>
                        <option value="25000">25000</option>
                    </select>
                </div>
                <div className="flex grid gap-4 mr-12">
                    <Button
                        className="border px-2"
                        onClick={() => {
                            setUsedButton(0);
                            setLastCalculation(
                                length +
                                    ('-' + rulesetName + (productType === 4 || productType === 2)
                                        ? '-Room'
                                        : '' + '-Ceiling' + (productType === 4 || productType === 2)
                                        ? '-opal'
                                        : '') +
                                    '-Purelite-Direct-Ceiling-opal'
                            );
                            let lineLength = length;

                            let power = { name: 'HPE', lumen: null, watt: null };
                            let color = { kelvin: 4000, name: 'Neutralweiss' };
                            // let lightDirection = "direktstrahlend";
                            let lightDirection = 'raumstrahlend';
                            let mounting = 'ceiling';
                            let form = 'I';
                            let diffusor = 'opal';
                            setValues(lineLength, power, color, lightDirection, mounting, form, diffusor);
                        }}
                    >
                        {length}-{rulesetName}
                        {productType === 4 || productType === 2 ? '-Room' : ''}-Ceiling{productType === 4 || productType === 2 ? '-opal' : ''}{' '}
                        {usedButton === 0 ? ' √' : ''}
                    </Button>
                    {productType === 4 || productType === 2 ? (
                        <>
                            <Button
                                className="border px-2"
                                onClick={() => {
                                    setUsedButton(1);
                                    setLastCalculation(length + '-Purelite-Direct-Ceiling-Run+');
                                    let lineLength = length;

                                    let power = { name: 'HPE', lumen: null, watt: null };
                                    let color = { kelvin: 4000, name: 'Neutralweiss' };
                                    // let lightDirection = "direktstrahlend";
                                    let lightDirection = 'raumstrahlend';
                                    let mounting = 'ceiling';
                                    let form = 'I';
                                    let diffusor = 'run+';
                                    setValues(lineLength, power, color, lightDirection, mounting, form, diffusor);
                                }}
                            >
                                {length}-{rulesetName}-Room-Ceiling-RUN+ {usedButton === 1 ? ' √' : ''}
                            </Button>
                            <Button
                                className="border px-2"
                                onClick={() => {
                                    setUsedButton(2);
                                    setLastCalculation(length + '-Purelite-Direct-Ceiling-RUN');
                                    let lineLength = length;

                                    let power = { name: 'HPE', lumen: null, watt: null };
                                    let color = { kelvin: 4000, name: 'Neutralweiss' };
                                    let lightDirection = 'direktstrahlend';
                                    // let lightDirection = "raumstrahlend";
                                    let mounting = 'ceiling';
                                    let form = 'I';
                                    let diffusor = 'run';
                                    setValues(lineLength, power, color, lightDirection, mounting, form, diffusor);
                                }}
                            >
                                {length}-{rulesetName}-Direct-Ceiling-RUN {usedButton === 2 ? ' √' : ''}
                            </Button>
                            <Button
                                className="border px-2"
                                onClick={() => {
                                    setUsedButton(3);
                                    setLastCalculation(length + '-Purelite-Direct-Ceiling-RUN+');
                                    let lineLength = length;

                                    let power = { name: 'HPE', lumen: null, watt: null };
                                    let color = { kelvin: 4000, name: 'Neutralweiss' };
                                    let lightDirection = 'direktstrahlend';
                                    // let lightDirection = "raumstrahlend";
                                    let mounting = 'ceiling';
                                    let form = 'I';
                                    let diffusor = 'run+';
                                    setValues(lineLength, power, color, lightDirection, mounting, form, diffusor);
                                }}
                            >
                                {length}-{rulesetName}-Direct-Ceiling-RUN+ {usedButton === 3 ? ' √' : ''}
                            </Button>
                        </>
                    ) : null}
                </div>
                <div className="flex w-80 items-center justify-between">
                    <div>Last Selection:</div>
                    <div>{lastCalculation}</div>
                </div>
            </div>
            <DialuxButton />
            <ArticleList />
        </>
    );
}
